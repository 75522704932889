
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AppButton from '@/components/atoms/AppButton.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { getDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterOutputSelect',
  components: {
    AppButton,
    DssIcon,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const disabledFlag = ref(true)

    const getList = async () => {
      const url = `${BASE_URL}/outer/item/list/check`
      const response = await getDataCredential(url)
      disabledFlag.value = response.status !== 200
    }
    getList()

    const resetFileUploadFlow = async () => {
      await store.dispatch('setS3UploadFileList', [])
      await store.dispatch('setContentsData', {})
      await store.dispatch('setReacquireFlag', true)
      await store.dispatch('setReuseFlag', false)
    }

    return {
      router,
      disabledFlag,

      async toContentsInput () {
        await resetFileUploadFlow()
        await router.push('contentsInput')
      },
      async toSelect () {
        await store.dispatch('setReuseFlag', true)
        await router.push('select')
      },
    }
  },
})
